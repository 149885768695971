var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex px-3 cursor-pointer py-2 gap-1 my-0.5 relative w-full text-left group rounded-lg items-start",class:{
    'bg-white shadow-md': _vm.data.key == _vm.selected,
    'bg-gray-100 hover:text-gray-800': _vm.data.key !== _vm.selected
  }},[_c('div',{staticClass:"flex-1 pl-1 min-w-0 flex flex-col gap-1"},[_c('div',{staticClass:"flex-1 overflow-hidden"},[(_vm.collectionTitle)?_c('v-clamp',{staticClass:"font-medium pr-5 text-xs text-gray-500",attrs:{"autoresize":"","max-lines":1}},[_vm._v(" "+_vm._s(_vm.collectionTitle)+" ")]):_vm._e(),_c('v-clamp',{staticClass:"font-medium pr-5",class:{
          'h-12': _vm.collectionTitle == null,
          'h-8': _vm.collectionTitle != null
        },attrs:{"autoresize":"","max-lines":2}},[_vm._v(" "+_vm._s(_vm.data.title || 'Sem título')+" ")])],1),_c('div',{staticClass:"flex gap-2 items-center"},[(_vm.data.updated_date)?_c('div',{staticClass:"text-xs text-gray-500 flex gap-2 flex-1"},[_c('div',[_vm._v("Atualizada em "+_vm._s(_vm._f("formatDateTime")(_vm.data.created_date)))])]):(_vm.data.created_date)?_c('div',{staticClass:"text-xs text-gray-500 flex gap-2 flex-1"},[_c('div',[_vm._v("Criada em "+_vm._s(_vm._f("formatDateTime")(_vm.data.created_date)))])]):_vm._e(),(_vm.data.status == 'draft')?_c('fw-tag',{attrs:{"type":"light-orange"}},[_vm._v("Rascunho")]):(_vm.data.status == 'published')?_c('fw-tag',{attrs:{"type":"light-primary"}},[_vm._v("Publicada")]):_vm._e()],1)]),_c('fw-dot',{staticClass:"m-1",attrs:{"color":_vm.data.has_updates ? 'primary' : 'light',"ping":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }