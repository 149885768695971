<template>
  <section>
    <fw-panel
      title="Formadores"
      :boxed="managersCount ? 'xs' : null"
      custom-class="bg-white"
      class="my-5"
      :loading="savingData"
      after-loading-checked
    >
      <div class="flex flex-1 px-2 pb-2">
        <fw-button
          size="md"
          :type="activePage === 'managers' ? 'tab-active' : 'tab'"
          @click.native="activePage = 'managers'"
        >
          Ativos
          <span v-if="!loading" class="text-xs opacity-50 ml-2">{{
            activePage === 'managers' ? managersCount : totalRemainingTabResults
          }}</span>
        </fw-button>

        <fw-button
          size="md"
          :type="activePage === 'pending-invites' ? 'tab-active' : 'tab'"
          @click.native="activePage = 'pending-invites'"
        >
          Pendentes
          <span v-if="!loading" class="text-xs opacity-50 ml-2">{{
            activePage === 'pending-invites' ? managersCount : totalRemainingTabResults
          }}</span>
        </fw-button>
      </div>
      <LoadingPlaceholder v-if="loading" list></LoadingPlaceholder>
      <ExpandList
        v-else
        add-title="Adicionar formadores"
        :show-add-items="canEdit"
        :disabled="!canEdit"
        empty-title="Não foram associados formadores."
        :empty="managersCount === 0"
        @add-element="choosePeople()"
      >
        <template #list>
          <fw-person
            v-for="(roles, userkey, index) in managers"
            :key="userkey"
            :class="{
              'border-b border-gray-100': index < managersCount - 1
            }"
            :clickable="canEdit"
            :person="users[userkey]"
            :selectable="false"
            @clicked="
              openPersonDetails({
                ...users[userkey],
                modules: roles.filter(el => el?.unit_key)?.map(role => units[role.unit_key])
              })
            "
          >
            <template #secondline>
              <div v-if="users[userkey]" class="text-sm text-gray-500 font-normal flex gap-2 items-center">
                {{ users[userkey].email }}
              </div>
              <div class="text-sm text-gray-500 font-normal flex gap-2 items-center pt-2">
                <template v-for="role in roles">
                  <b-tooltip
                    v-if="role.unit_key"
                    :key="role.unit_key"
                    type="is-light"
                    :label="units[role.unit_key].title"
                    position="is-bottom"
                  >
                    <fw-tag type="light-border-box" size="xs">
                      <span class="truncate max-w-[20ch]">{{ units[role.unit_key].title }}</span>
                    </fw-tag>
                  </b-tooltip>
                </template>
                <fw-tag v-if="!roles.filter(el => el?.unit_key).length" type="light-primary-border-box" size="xs">
                  Todos os modulos
                </fw-tag>
              </div>
            </template>

            <template v-if="canRemove && user.key !== userkey" #options>
              <fw-menu-more>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button type="basic-action" custom-class="w-full" @click.native="removeManager(userkey)">
                    Remover</fw-button
                  >
                </b-dropdown-item>
              </fw-menu-more>
            </template>

            <template v-if="invites[userkey]" #details-modal-more-meta>
              <BlockInviteDetails
                :user="users[userkey]"
                :invite.sync="invites[userkey]"
                :send-invite-endpoint="sendInvite"
              ></BlockInviteDetails>
            </template>
          </fw-person>
        </template>
      </ExpandList>

      <fw-panel-info debug label="Data (raw)">
        <json-viewer :value="{ managers, activePage, totalRemainingTabResults, managersCount }"></json-viewer>
      </fw-panel-info>
    </fw-panel>

    <fw-modal :active.sync="isModalOpen" :can-cancel="true" boxed="xs" size="min" width="42rem" @close="closeModal">
      <ModalChooseModulesManagers
        v-if="isModalOpen"
        title="Selecione os utilizadores que quer como formadores dos módulos:"
        instructions="Escolha os formadores dos módulos"
        :multiselect="true"
        :endpoint="searchEndpoint"
        :not-alowed-users="excludeUsers"
        :course-key="course.key"
        :edition-key="edition.key"
        :invite-people="true"
        @save-invite="saveInvite"
        @selected="selectedManagers"
        @close="closeModal"
      ></ModalChooseModulesManagers>
    </fw-modal>

    <fw-modal
      :active.sync="activeUserDetailsModal"
      :can-cancel="true"
      boxed="xs"
      size="min"
      width="42rem"
      @close="closePersonDetails"
    >
      <ModalModuleManagerUserDetails
        v-if="activeUserDetailsModal"
        :user="selectedUser"
        :saving-data="savingData"
        :course-key="course.key"
        :edition-key="edition.key"
        :invite.sync="invites[selectedUser.key]"
        @update="updateManager($event)"
        @close="closePersonDetails"
      />
    </fw-modal>
  </section>
</template>

<script>
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import ModalChooseModulesManagers from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalChooseModulesManagers'
import ModalModuleManagerUserDetails from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalModuleManagerUserDetails'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import BlockInviteDetails from '@/fw-modules/fw-core-vue/courses/components/blocks/BlockInviteDetails'
export default {
  components: {
    LoadingPlaceholder,
    ExpandList,
    ModalChooseModulesManagers,
    ModalModuleManagerUserDetails,
    BlockInviteDetails
  },

  props: {
    course: {
      type: Object,
      default: () => ({})
    },
    edition: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      isModalOpen: false,
      loading: false,
      savingData: false,
      validations: {
        can_add: false,
        can_delete: false
      },
      managers: {},
      users: {},
      units: {},
      invites: {},

      activeUserDetailsModal: false,
      selectedUser: {},
      totalRemainingTabResults: 0,
      activePage: 'managers'
    }
  },

  computed: {
    excludeUsers() {
      return []
    },
    canEdit() {
      return !!this.validations.can_add
    },
    canRemove() {
      return !!this.validations.can_delete
    },
    managersCount() {
      return Object.keys(this.managers).length || 0
    },
    user() {
      return this.$store.getters.getUser
    }
  },

  watch: {
    activePage() {
      console.log('activePage', this.activePage)
      this.getManagers()
    }
  },

  mounted() {
    this.getManagers()
  },

  methods: {
    async searchEndpoint(params) {
      return await ServiceCourses.searchUsers(this.course.key, null, null, { ...params, adding: 'unit_instructor' })
    },

    async getManagers() {
      this.loading = true
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getUnitsManagers(this.course.key, this.edition.key, {
          active: this.activePage == 'pending-invites'
        })
        console.log('getUnitsManagers :>> ', response)
        this.managers = response.managers
        if (response.validations) {
          this.validations = response.validations
        }
        this.users = { ...this.users, ...response.users }
        this.units = { ...this.units, ...response.units }
        this.invites = { ...this.invites, ...response.invites }
        this.totalRemainingTabResults = response.total_remaining_invites
      })

      this.loading = false
    },

    choosePeople() {
      this.isModalOpen = true
    },

    closeModal() {
      this.isModalOpen = false
    },

    selectedManagers(managers) {
      console.log('selectedManagers :>> ', managers)
      this.addManagers(managers)
      this.closeModal()
    },

    async addManagers({ users, invites, units, is_global }) {
      if (!users?.length && !invites?.length) return
      const toAdd = invites?.length ? (users ?? []).concat(invites) : users

      this.savingData = true
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.addUnitsManagers(this.course.key, this.edition.key, {
          user_keys: toAdd.map(el => el.key),
          unit_keys: units?.map(el => el.key),
          is_global
        })
        console.log('addUnitsManagers :>> ', response)

        const usersKeys = users?.map(el => el.key) ?? []
        const invitedUsersKeys = invites?.map(el => el.key) ?? []

        for (const [userkey, roles] of Object.entries(response.managers)) {
          if (usersKeys.includes(userkey)) {
            if (this.activePage == 'managers') this.$set(this.managers, userkey, roles)
            else this.totalRemainingTabResults += 1
          } else if (invitedUsersKeys.includes(userkey)) {
            if (this.activePage == 'pending-invites') this.$set(this.managers, userkey, roles)
            else this.totalRemainingTabResults += 1
          }
        }
        // this.managers = { ...this.managers, ...response.managers }
        this.users = { ...this.users, ...response.users }
        this.units = { ...this.units, ...response.units }
        this.invites = { ...this.invites, ...response.invites }

        this.$buefy.snackbar.open({
          message: `Formadores adicionados.`,
          type: 'is-primary',
          position: 'is-top-right',
          duration: 3000
        })
      })
      this.savingData = false
    },

    async removeManager(userkey) {
      console.log('removeManager :>> ', userkey)
      this.$buefy.dialog.confirm({
        title: 'Remover gestor',
        message: `Tem a certeza que deseja remover o formador?`,
        confirmText: 'Remover',
        type: 'is-danger',
        onConfirm: async () => {
          this.savingData = true
          await utils.tryAndCatch(this, async () => {
            await ServiceCourses.deleteUnitsManager(this.course.key, this.edition.key, userkey)
            this.$delete(this.managers, userkey)
            this.$buefy.snackbar.open({
              message: `Gestor removido.`,
              type: 'is-primary',
              position: 'is-top-right',
              duration: 3000
            })
          })
          this.savingData = false
        }
      })
    },

    async updateManager({ userkey, unitKeys, is_global }) {
      this.savingData = true
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.updateUnitsManagers(
          this.course.key,
          this.edition.key,
          userkey,
          unitKeys,
          is_global
        )
        console.log('updateManager :>> ', response)
        this.$set(this.managers, userkey, response.roles)
        this.users = { ...this.users, ...response.users }
        this.units = { ...this.units, ...response.units }

        this.$buefy.snackbar.open({
          message: `Formadores adicionados.`,
          type: 'is-primary',
          position: 'is-top-right',
          duration: 3000
        })
      })
      this.savingData = false
    },

    openPersonDetails(user) {
      console.log('openPersonDetails :>> ', user)
      this.selectedUser = user
      this.activeUserDetailsModal = true
    },

    closePersonDetails() {
      console.log('closePersonDetails')
      this.activeUserDetailsModal = false
      this.selectedUser = {}
    },

    async saveInvite(inviteData) {
      try {
        const response = await ServiceCourses.createUsersInvites([inviteData], true)
        const invites = response.invites
        console.log('invited ==>', invites)
        for (const invite of invites) {
          this.$buefy.snackbar.open({
            message: this.$t('accountAdded', { user: invite.name, email: invite.email }),
            type: 'is-primary',
            position: 'is-top-right',
            duration: 2000,
            queue: false
          })
        }

        this.closeModal()
        this.selectedManagers({ invites: invites, is_global: true })
      } catch (error) {
        const apiError = utils.errors(error).get()
        console.log('apiError :>> ', apiError)
        if (apiError && apiError.key && apiError.key == 'UserAlreadyExists') {
          this.$buefy.dialog.alert({
            title: this.$t('userAlreadyExists', { user: apiError.name }),
            message: this.$t('userAlreadyExistsInfo'),
            type: 'is-danger'
          })
        } else {
          utils.errorDialogAlert(this, error)
        }

        console.error(error)
      }
    }
  }
}
</script>

<i18n lang="json">
{
  "pt": {
    "leader": "Líder",
    "confirmedAt": "Confirmada em",
    "deniedAt": "Rejeitada em",
    "deletedAt": "Removida em",
    "messages": "Mensagens",
    "logs": "Atividade",
    "accountAdded": "A conta de {user} ({email}) foi adicionada.",
    "userAlreadyExists": "O utilizador {user} já existe",
    "userAlreadyExistsInfo": "O utilizador que tentou convidar já existe."
  },
  "en": {
    "leader": "Líder",
    "confirmedAt": "Confirmada em",
    "deniedAt": "Rejeitada em",
    "deletedAt": "Removida em",
    "messages": "Mensagens",
    "logs": "Atividade",
    "accountAdded": "The account of {user} ({email}) was added.",
    "userAlreadyExists": "User {user} already exists",
    "userAlreadyExistsInfo": "The user you tried to invite already exists."
  }
}
</i18n>
