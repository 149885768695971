<template>
  <a :href="openItem" class="group bg-gray-200 rounded-xl overflow-hidden flex flex-col gap-1 p-1">
    <div class="relative">
      <div v-if="backgroundImage" class="overflow-hidden rounded-t-xl">
        <img :src="backgroundImage" alt="Imagem de capa" class="w-full h-44 object-cover object-center" />
      </div>
      <div
        class="px-4 py-3 overflow-hidden rounded-lg"
        :class="{
          'absolute top-0 left-0 w-full z-10 bg-gradient-to-b from-gray-800 to-transparent h-20 text-white': backgroundImage
        }"
      >
        <div class="flex gap-3 justify-between items-center opacity-90">
          <fw-icon-cube class="w-6 h-6" />
          <div>
            <fw-tag :type="edition.status === 'published' ? 'primary' : backgroundImage ? 'white' : 'light'">{{
              statusLabels[edition.status]
            }}</fw-tag>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 px-4 py-3 text-gray-500 flex flex-col justify-end gap-2">
      <div class=" flex-1 font-bold uppercase text-lg">
        <v-clamp autoresize :max-lines="3">
          {{ edition.title }}
        </v-clamp>
      </div>
      <div class="flex flex-col gap-0.5 font-semibold">
        <div>
          {{ edition.participants.total }} {{ edition.participants.total == 1 ? 'Participante' : 'Participantes' }}
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  name: 'CardManageCourseEdition',

  props: {
    course: {
      type: Object,
      required: true
    },
    edition: {
      type: Object,
      required: true
    }
  },

  computed: {
    statsParticipants() {
      return this.edition.stats && this.edition.stats.students ? this.edition.stats.students : 0
    },
    statsInbox() {
      return this.edition.stats && this.edition.stats.inbox ? this.edition.stats.inbox : 0
    },
    statusLabels() {
      return {
        draft: 'Rascunho',
        published: 'Publicado',
        archived: 'Arquivado',
        closed: 'Terminado'
      }
    },
    backgroundImage() {
      return this.edition.cover ? ServiceStorage.getImageViewUrl(this.edition.cover) : null
    },
    openItem() {
      return this.$router.resolve({
        name: 'course-manage-edition',
        params: {
          id: this.course.key,
          editionId: this.edition.key
        }
      }).href
    }
  }
}
</script>
