<template>
  <div>
    <ChoosePeopleModal
      :title="title"
      :instructions="instructions"
      :multiselect="multiselect"
      :not-alowed-users="notAlowedUsers"
      :invite-people="invitePeople"
      :endpoint="endpoint"
      :auto-close="false"
      @save-invite="$emit('save-invite', $event)"
      @selected-updated="selectedPeople"
      @confirm="saveSelection"
      @close="$emit('close')"
    >
      <template v-if="selectModules" #after-list>
        <div v-if="selectedUsers.length" class="ml-2">
          <b-switch v-model="asGlobalInstructor">
            Formador em todos os módulos
          </b-switch>

          <div v-if="!asGlobalInstructor" class="mt-5">
            <fw-label>Módulos</fw-label>
            <multiselect
              v-model="selectedModules"
              :options="modules"
              :show-labels="false"
              :clear-on-select="false"
              :searchable="true"
              :multiple="true"
              placeholder="Selecione os módulos"
              track-by="key"
              label="title"
              :loading="loadingModules"
              @search-change="searchModules"
            ></multiselect>

            <fw-tip v-if="$v.selectedModules.$error" error>
              Escolha pelo menos um módulo
            </fw-tip>
          </div>
        </div>
      </template>
    </ChoosePeopleModal>

    <fw-tip v-if="selectModules && ($v.selectedModules.$error || $v.selectedUsers.$error)" error>
      <span> Escolha pelo menos um utilizador</span>
    </fw-tip>
  </div>
</template>

<script>
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import Multiselect from 'vue-multiselect'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    ChoosePeopleModal,
    Multiselect
  },
  props: {
    multiselect: {
      type: Boolean,
      default: true
    },
    invitePeople: {
      type: Boolean,
      default: true
    },
    selectModules: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    instructions: {
      type: String,
      default: ''
    },
    notAlowedUsers: {
      //not alowed user keys
      type: Array,
      default: function() {
        return []
      }
    },
    endpoint: {
      type: Function
    },
    courseKey: {
      type: String
    },
    editionKey: {
      type: String
    }
  },
  data() {
    return {
      searchQueryId: '',
      selectedUsers: [],
      selectedModules: [],
      modules: [],
      loadingModules: false,
      asGlobalInstructor: true,
      debounceTimer: null
    }
  },

  validations() {
    return {
      selectedUsers: { required, min: minLength(1) },
      selectedModules: {
        required: value => this.asGlobalInstructor === true || (value && value?.length > 0)
      }
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    }
  },

  mounted() {
    this.getUnits()
  },

  methods: {
    searchModules(query) {
      this.debounce(async () => {
        await this.getUnits(query)
      })
    },

    async getUnits(query) {
      this.loadingModules = true

      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getUnits(this.courseKey, this.editionKey, {
          query,
          limit: 50
        })
        console.log('getUnits :>> ', response)
        this.modules = response.units
      })

      this.loadingModules = false
    },

    debounce(func, timeout = 300) {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer)
      }
      this.debounceTimer = setTimeout(() => {
        func.apply()
      }, timeout)
    },

    selectedPeople(selected) {
      console.log('Select THIS ONE', selected)
      this.selectedUsers = selected
    },

    saveSelection() {
      if (!this.selectModules) {
        this.$emit('selected', {
          users: this.selectedUsers
        })
        return
      }
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$emit('selected', {
        users: this.selectedUsers,
        units: this.selectedModules,
        is_global: this.asGlobalInstructor
      })

      this.$v.$reset
      this.selectedUsers = []
      this.selectedModules = []
    }
  }
}
</script>
