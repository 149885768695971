<template>
  <fw-layout
    wide
    paddingless
    :loading="loadingInitial"
    mobile-ready
    loading-title="Espaço de Aprendizagem"
    main-content-scrollable
    main-listbar-width="w-[23rem]"
    main-sidebar-width="w-auto"
    :back-to-enable="false"
    :title="module?.title"
    :subtitle="topic?.title"
  >
    <template #header-nav>
      <div class="flex gap-5 items-center">
        <div class="flex items-center">
          <fw-button type="transparent" @click.native="goToSpace()">
            <fw-icon-cube class="w-5 h-5" />
          </fw-button>
          <div class="h-8 w-5 text-center font-medium text-2xl text-gray-200">/</div>
          <fw-button-dropdown
            aria-role="listitem"
            type="transparent"
            :chevron="false"
            :expanded="false"
            @click.native="goToEdition()"
          >
            <span class="font-semibold text-base line-clamp-1 max-w-xs">{{ edition.title ?? 'Edição' }}</span>
          </fw-button-dropdown>
          <div class="h-8 w-5 text-center font-medium text-2xl text-gray-200">/</div>
          <b-dropdown aria-role="list" position="is-bottom-right" append-to-body>
            <fw-button-dropdown slot="trigger" aria-role="listitem" type="transparent">
              <span class="font-semibold text-base line-clamp-1 max-w-xs">Módulos</span>
            </fw-button-dropdown>
            <b-dropdown-item custom paddingless aria-role="menuitem">
              <SidebarManageCourseEdition
                :course-key="courseKey"
                :edition-key="editionKey"
                :validations="validations"
                is-bread-crumb
              />
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </template>
    <template #main-sidebar>
      <SidebarList
        ref="unitsSidebar"
        data-title="Módulos"
        data-key="units"
        :api-call-function="loadModules"
        :no-data-label="'Sem módulos criados'"
        :starts-open="true"
        :show-filters="false"
        :show-searchbar="false"
        collapsable
        width="w-[23rem]"
        :scroll-to-el-id="moduleKey ? `sidebar_module_${moduleKey}` : null"
        @open-first="firstLoad"
      >
        <template #records="{ items, users }">
          <Container
            v-if="validations?.edition?.can_edit"
            group-name="topics"
            drop-class="card-ghost-drop"
            :drop-placeholder="dropPlaceholderOptions"
            :should-animate-drop="() => true"
            :get-child-payload="index => items[index]"
            drag-handle-selector=".drag-handle-new"
            class="flex flex-col gap-1"
            @drop="onDropSection($event, 'module')"
          >
            <Draggable
              v-for="(moduleData, index) in items"
              :id="`sidebar_module_${moduleData.key}`"
              :key="`sidebar_module_${moduleData.key}`"
              class="relative box-question border-b border-gray-100 pb-0.5 group"
            >
              <div class="drag-handle-new top-2.5 opacity-50 group-hover:opacity-100">
                <fw-icon-draggable class="w-5 h-5" />
              </div>
              <RecordModule
                :data="{ ...moduleData, index }"
                :edition-key="editionKey"
                :course-key="courseKey"
                :editable="false"
                :users="users"
                :selected="moduleKey"
                draggable
                @open="openModule(moduleData)"
              ></RecordModule>
            </Draggable>
          </Container>
          <div v-else>
            <RecordModule
              v-for="moduleData in items"
              :id="`sidebar_module_${moduleData.key}`"
              :key="moduleData.key"
              class="mb-1"
              :data="moduleData"
              :selected="moduleKey"
              :edition-key="editionKey"
              :course-key="courseKey"
              :editable="false"
              :users="users"
              @open="openModule(moduleData)"
            ></RecordModule>
          </div>
        </template>
        <template #collapsed-records="{ items, users }">
          <RecordModule
            v-for="moduleData in items"
            :id="`sidebar_module_short_${moduleData.key}`"
            :key="moduleData.key"
            class="mb-1"
            :data="moduleData"
            :edition-key="editionKey"
            :course-key="courseKey"
            :selected="moduleKey"
            :editable="false"
            :users="users"
            short-sidebar
            @open="openModule(moduleData)"
          ></RecordModule>
        </template>
        <template v-if="validations.edition.can_edit" #pre-toolbar>
          <fw-button type="light-primary" icon="add-line" size="sm" @click.native="openModal('create-unit')">
            Criar módulo
          </fw-button>
        </template>
        <template v-if="validations.edition.can_edit" #after-collapsed>
          <div class="p-3">
            <button
              class="flex gap-2 relative w-full flex flex-col leading-7 text-center py-2 text-left group rounded-lg items-center bg-white hover:bg-primary/10 border border-gray-200 bg-gray-100"
              @click="openModal('create-unit')"
            >
              <fw-icon-add-line class="w-5 h-5 text-primary" />
            </button>
          </div>
        </template>
      </SidebarList>
    </template>

    <template #main-listbar>
      <SidebarList
        v-if="moduleKey"
        :key="moduleKey"
        ref="topicsSidebar"
        :data-title="'Temas'"
        data-key="topics"
        width="w-auto"
        :api-call-function="loadModuleTopics"
        :show-filters="false"
        :show-searchbar="false"
        :collapsable="false"
        class="h-auto"
        :no-data-label="'Sem temas criados'"
        :scroll-to-el-id="topicKey ? `sidebar_topic_${topicKey}` : null"
        @loaded="updateCurrentTopic"
      >
        <template v-if="validations.unit.can_edit" #pre-toolbar>
          <fw-button type="light-primary" icon="add-line" size="sm" @click.native="openModal('create-topic')">
            Criar tema
          </fw-button>
        </template>
        <template #before-records>
          <router-link
            :to="{
              name: 'course-manage-edition-module',
              params: { id: courseKey, editionId: editionKey, moduleId: moduleKey }
            }"
            class="px-4 pt-3 mt-1"
          >
            <div
              class="flex px-4 py-3.5 gap-3 relative w-full text-left group rounded-lg text-base items-center justify-between hover:bg-gray-100"
              :class="{
                'text-primary bg-white shadow': !topicKey,
                'bg-gray-300 bg-opacity-20 text-gray-600 hover:text-gray-800 shadow-sm': topicKey
              }"
            >
              <div class="flex gap-3 items-center justify-between">
                <div>
                  <fw-icon-book-mark-solid class="w-5 h-5 flex-shrink-0" />
                </div>
                <div class="font-medium">Apresentação do módulo</div>
              </div>
            </div>
          </router-link>
        </template>
        <template #records="{ items }">
          <Container
            v-if="validations?.unit?.can_edit"
            group-name="topics"
            drop-class="card-ghost-drop"
            :drop-placeholder="dropPlaceholderOptions"
            :should-animate-drop="() => true"
            :get-child-payload="index => items[index]"
            drag-handle-selector=".drag-handle-new"
            class="flex flex-col gap-1"
            @drop="onDropSection($event, 'topic')"
          >
            <Draggable
              v-for="(topicData, index) in items"
              :id="`sidebar_topic_${topicData.key}`"
              :key="`sidebar_topic_${topicData.key}`"
              class="relative box-question group"
            >
              <div class="drag-handle-new top-2.5 opacity-50 group-hover:opacity-100">
                <fw-icon-draggable class="w-5 h-5" />
              </div>
              <RecordTopic
                :data="{ ...topicData, index }"
                :selected="topicKey"
                @click.native="openTopic(items[index])"
              />
            </Draggable>
          </Container>

          <div v-else>
            <RecordTopic
              v-for="topicData in items"
              :id="`sidebar_topic_${topicData.key}`"
              :key="`sidebar_topic_${topicData.key}`"
              class="mb-1"
              :data="topicData"
              :selected="topicKey"
              @click.native="openTopic(items[index])"
            />
          </div>
        </template>
      </SidebarList>
    </template>

    <template #main-content>
      <PanelManageCourseEditionModuleTopic
        v-if="topicKey && topic"
        :topic="topic"
        :course-key="courseKey"
        :edition-key="editionKey"
        :unit-key="moduleKey"
        sticky
        :loading="loading"
        :can-edit="validations.unit.can_edit"
        :view="$route.name === 'course-manage-edition-module-topic-agenda' ? 'agenda' : 'dashboard'"
        :collections="collections"
        :collections-contexts-data="collectionsContextsData"
        :classes="classes"
        :sessions.sync="sessions"
        @open-post="openPost"
        @delete-topic="handleDeleteTopic"
        @updated-topic="handleUpdatedTopic"
      />
      <PanelManageCourseEditionModule
        v-else-if="moduleKey && module"
        :unit="module"
        :course-key="courseKey"
        :edition-key="editionKey"
        :can-edit="validations.unit.can_edit"
        sticky
        :loading="loading"
        :view="$route.name === 'course-manage-edition-module-people' ? 'people' : 'dashboard'"
        :collections="collections"
        :collections-contexts-data="collectionsContextsData"
        @open-post="openPost"
        @delete-unit="handleDeleteUnit"
        @new-topic="handleNewTopic"
        @updated-unit="handleUpdatedUnit"
      />
      <div v-else class="py-20 text-center text-gray-500">Seleccione um módulo</div>
      <fw-panel-info debug label="Data (raw)" class="mt-auto">
        <json-viewer :value="{ units, module, topic, collections, classes, sessions }"></json-viewer>
      </fw-panel-info>
    </template>

    <template #modals>
      <div
        v-if="post?.key"
        class="fixed top-0 left-0 right-0 bottom-0 bg-black/20 z-[11] backdrop-blur-md overflow-y-auto"
      >
        <button
          class="fixed z-20 top-20 right-5 h-10 w-10 flex flex-col items-center justify-center text-white bg-black/50 rounded-full"
          @click="post = null"
        >
          <fw-icon-close class="w-7 h-7" />
        </button>
        <div class="bg-white py-2 rounded-lg mx-auto w-full max-w-3xl my-20">
          <PanelPost
            :id="post.key"
            :key="post.key"
            :collection-key="post.collection.key"
            :bottom-margin="false"
            @deleted="post = null"
          />
        </div>
      </div>
      <fw-modal v-if="modalIsActive" :active.sync="modalIsActive" :can-cancel="true" size="min" @close="closeModal()">
        <ModalNewModule
          v-if="modalAction === 'edit-unit'"
          :title="unit.title"
          @save="editUnitTitle($event)"
          @cancel="closeModal()"
        />
        <ModalNewModule
          v-else-if="modalAction === 'create-unit'"
          @save="createNewUnit($event)"
          @cancel="closeModal()"
        />
        <ModalNewTopic
          v-else-if="modalAction === 'create-topic'"
          @save="createNewTopic($event)"
          @cancel="closeModal()"
        />
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import SidebarList from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarList'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import RecordModule from '@/fw-modules/fw-core-vue/courses/components/records/RecordModule'
import RecordTopic from '@/fw-modules/fw-core-vue/courses/components/records/RecordTopic'
import PanelManageCourseEditionModuleTopic from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionModuleTopic'
import PanelManageCourseEditionModule from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionModule'
import ModalNewModule from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewModule'
import ModalNewTopic from '@/fw-modules/fw-core-vue/courses/components/modals/manage/ModalNewTopic'
import PanelPost from '@/fw-modules/fw-core-vue/posts/components/panels/PanelPost'
import { Container, Draggable } from 'vue-dndrop'
import SidebarManageCourseEdition from '@/fw-modules/fw-core-vue/courses/components/sidebars/manage/SidebarManageCourseEdition'
export default {
  components: {
    SidebarList,
    RecordModule,
    RecordTopic,
    Container,
    Draggable,
    PanelManageCourseEditionModuleTopic,
    PanelManageCourseEditionModule,
    ModalNewTopic,
    ModalNewModule,
    PanelPost,
    SidebarManageCourseEdition
  },
  data() {
    return {
      navigation: [],
      loadingInitial: true,
      loading: false,
      pagination: {
        total_items: 0,
        total_pages: 0
      },
      page: 1,
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      units: [],
      module: [],
      topic: null,
      collections: [],
      collectionsContextsData: {},
      classes: [],
      sessions: {},
      post: {},
      validations: {
        unit: {
          can_edit: false
        },
        edition: {
          can_edit: false
        }
      },
      edition: {},
      modalIsActive: false,
      modalAction: null
    }
  },

  computed: {
    lang() {
      return this.$store.state.language
    },

    courseKey() {
      return this.$route.params.id
    },

    editionKey() {
      return this.$route.params.editionId
    },

    moduleKey() {
      return this.$route.params.moduleId
    },

    topicKey() {
      return this.$route.params.topicId || null
    },

    user() {
      return this.$store.getters.getUser
    },

    mainContentTitle() {
      if (this.topicKey && this.topic) {
        return `Tema ${this.topic.title}`
      }

      if (this.moduleKey && this.module) {
        return `Módulo ${this.module.title}`
      }

      return ''
    }
  },

  watch: {
    moduleKey() {
      this.getPageData()
    },

    topicKey() {
      this.getPageData()
    }
  },

  mounted() {
    this.getPageData()
  },

  methods: {
    updateCurrentTopic(data) {
      console.log('updateCurrentTopic', data.data)
      if (this.topic != null && data.data && data.data.length > 0) {
        //find topic in data
        let foundtopic = data.data.find(t => t.key == this.topic.key)
        if (foundtopic != null) {
          this.topic = foundtopic
          this.$refs.topicsSidebar.updateItemDetails(foundtopic)
        }
      }
    },
    goToEdition() {
      this.$router.push({
        name: 'course-manage-edition',
        params: { id: this.courseKey, editionId: this.edition.key, view: 'dashboard' }
      })
    },
    goToSpace() {
      this.$router.push({
        name: 'course-manage',
        params: { id: this.courseKey }
      })
    },
    openModal(action) {
      this.modalIsActive = true
      this.modalAction = action
    },

    closeModal() {
      this.modalIsActive = false
      this.modalAction = null
    },

    async loadModules(params) {
      return await ServiceCourses.getUnits(this.courseKey, this.editionKey, params)
    },

    async loadModuleTopics(params) {
      return await ServiceCourses.getTopics(this.courseKey, this.editionKey, this.moduleKey, params)
    },

    async getUnitPostsCollections() {
      if (!this.courseKey || !this.editionKey || !this.moduleKey) return
      await utils.tryAndCatch(this, async () => {
        const { collections, ...rest } = await ServiceCourses.getUnitPostsCollections(
          this.courseKey,
          this.editionKey,
          this.moduleKey,
          { context_details: true }
        )
        this.collections = collections
        this.collectionsContextsData = rest
      })
    },

    async getTopicPostsCollections() {
      if (!this.courseKey || !this.editionKey || !this.moduleKey || !this.topicKey) return
      await utils.tryAndCatch(this, async () => {
        const { collections, ...rest } = await ServiceCourses.getUnitTopicCollections(
          this.courseKey,
          this.editionKey,
          this.moduleKey,
          this.topicKey,
          { context_details: true }
        )
        this.collections = collections
        this.collectionsContextsData = rest
      })
    },

    async getTopicSessions() {
      if (!this.courseKey || !this.editionKey || !this.moduleKey || !this.topicKey) return
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getTopicSessions(
          this.courseKey,
          this.editionKey,
          this.moduleKey,
          this.topicKey
        )
        this.sessions = response.sessions
        console.log('getTopicSessions :>> ', response)
      })
    },

    async getTopic(params) {
      if (!this.courseKey || !this.editionKey || !this.moduleKey || !this.topicKey) return
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getTopic(
          this.courseKey,
          this.editionKey,
          this.moduleKey,
          this.topicKey,
          params
        )
        console.log('response :>> ', response)
        this.topic = response.topic
        this.module = response.unit
        this.validations.unit = response.validations
      })
    },

    async getUnit(params) {
      if (!this.courseKey || !this.editionKey || !this.moduleKey) return
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getUnit(this.courseKey, this.editionKey, this.moduleKey, params)
        console.log('response :>> ', response)
        this.module = response.unit
        this.validations.unit = response.validations
      })
    },

    async getClasses(params) {
      if (!this.courseKey || !this.editionKey) return
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getClasses(this.courseKey, this.editionKey, params)
        console.log('response :>> ', response)
        this.classes = response.classes
      })
    },

    async loadEditions() {
      if (!this.courseKey) return

      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getEditions(this.courseKey)
        console.log('editions', response)
        this.editions = response.editions
      })
    },

    async getPageData(params) {
      console.log('getUnitTopicData params :>> ', params)

      this.loading = true
      if (this.topicKey == null) {
        await Promise.all([
          this.getEdition(),
          this.getUnit(params),
          this.getUnitPostsCollections(),
          this.loadEditions()
        ])
      } else {
        // TODO: get only page specific
        await Promise.all([
          this.getEdition(),
          this.getTopic(params),
          this.getTopicPostsCollections(),
          this.getClasses(),
          this.getTopicSessions(),
          this.loadEditions()
        ])
      }

      if (this.loadingInitial) this.loadingInitial = false

      this.loading = false
    },

    async onDropSection(dropResult, type = 'module') {
      if (dropResult.addedIndex == null) return
      console.log('onDropSection', dropResult, type)
      if (type == 'module') {
        this.$refs.unitsSidebar.updateItems(null, data => {
          return this.applyDrag(data, dropResult)
        })
        await this.changeOrderModule(dropResult.payload.key, dropResult.addedIndex)
      } else if (type == 'topic') {
        this.$refs.topicsSidebar.updateItems(null, data => {
          return this.applyDrag(data, dropResult)
        })
        await this.changeOrderTopic(dropResult.payload.key, dropResult.addedIndex)
      }
    },

    async getEdition() {
      if (!this.courseKey || !this.editionKey) return
      utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getEdition(this.courseKey, this.editionKey)
        console.log('getEdition :>> ', response)
        this.edition = response.edition
        this.validations.edition = response.validations
      })
    },

    applyDrag(arr, dragResult) {
      console.log('applyDrag :>> ', { arr, dragResult })
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      console.log('result :>> ', result)

      return result
    },

    async changeOrderTopic(topicKey, newIndex) {
      console.log('changeOrderTopic', { topicKey, newIndex })

      if (newIndex == null) return

      await utils.tryAndCatch(this, async () => {
        let result = await ServiceCourses.changeOrderTopic(
          this.courseKey,
          this.editionKey,
          this.moduleKey,
          topicKey,
          newIndex
        )
        console.log('changeOrderTopic res', result)
        this.$buefy.snackbar.open({
          message: 'Temas reordenados com sucesso.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 3000
        })
      })
    },

    async changeOrderModule(moduleKey, newIndex) {
      console.log('changeOrderModule', { moduleKey, newIndex })

      await utils.tryAndCatch(this, async () => {
        let result = await ServiceCourses.changeOrderUnit(this.courseKey, this.editionKey, moduleKey, newIndex)
        console.log('changeOrderUnit res', result)
        this.$buefy.snackbar.open({
          message: 'Módulos reordenados com sucesso.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 3000
        })
      })
    },

    firstLoad(first) {
      console.log('firstLoad', first)
      //get module key from url
      let moduleKey = this.$route.params.moduleId
      //let currentTopicKey = this.$route.params.topicId
      console.log('moduleKey', moduleKey)
      if (moduleKey && this.topicKey) {
        //open module
        //this.openTopic({ key: this.topicKey }, moduleKey)
      } else if (moduleKey) {
        //this.openModule({ key: moduleKey })
      } else if (first) {
        //open first module
        this.openModule(first)
      }
    },

    openModule(module) {
      console.log('!!! openModule :>> ', module)
      this.$router.push({
        name: 'course-manage-edition-module',
        params: {
          id: this.courseKey,
          editionId: this.editionKey,
          moduleId: module ? module.key : null
        }
      })
    },

    openTopic(topic, unitKey = null) {
      console.log('!! openTopic :>> ', topic)
      this.topic = topic
      this.$router.push({
        name: 'course-manage-edition-module-topic',
        params: {
          id: this.courseKey,
          editionId: this.editionKey,
          moduleId: unitKey ? unitKey : this.moduleKey,
          topicId: topic.key
        }
      })
    },

    goToTopicPage(name) {
      this.$router.push({
        name,
        params: {
          id: this.courseKey,
          editionId: this.editionKey,
          moduleId: this.moduleKey,
          topicId: this.topicKey
        }
      })
    },

    goToModulePage(name) {
      this.$router.push({
        name,
        params: {
          id: this.courseKey,
          editionId: this.editionKey,
          moduleId: this.moduleKey
        }
      })
    },

    handleDeleteTopic() {
      console.log('handleDeleteTopic')
      this.$refs.topicsSidebar.forceUpdate()
    },

    async handleNewTopic(topic) {
      console.log('handleNewTopic', topic)
      await this.$refs.topicsSidebar.forceUpdate()
      this.openTopic(topic)
    },

    handleUpdatedTopic(topic) {
      console.log('handleUpdatedtopic', topic)
      if (topic != null) {
        this.$refs.topicsSidebar.updateItemDetails(topic)
      }
      if (this.$route.name === 'course-manage-edition-module-topic-agenda') {
        this.$refs.topicsSidebar.forceUpdate()
      }
    },

    async handleNewUnit(unit) {
      console.log('handleNewUnit >>', unit)
      await this.$refs.unitsSidebar.forceUpdate()
      this.openModule(unit)
    },

    handleUpdatedUnit(unit) {
      console.log('handleUpdatedUnit', unit)
      this.$refs.unitsSidebar.updateItemDetails(unit)
    },

    handleDeleteUnit() {
      //open first module
      if (this.units.length > 0) {
        this.openModule(this.units[0])
      } else {
        this.openModule(null)
      }
      console.log('handleDeleteUnit')
      this.$refs.unitsSidebar.forceUpdate()
    },

    async editUnitTitle({ title }) {
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.updateUnit(this.courseKey, this.editionKey, this.moduleKey, {
          title: title
        })
        console.log(response)
        this.unit.title = title
        this.handleUpdatedUnit(response)
        //show snackbar
        this.$buefy.snackbar.open({
          message: 'Título do módulo atualizado.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 5000
        })
      })
      this.closeModal()
    },

    async createNewUnit({ title }) {
      await utils.tryAndCatch(this, async () => {
        let result = await ServiceCourses.createUnit(this.courseKey, this.editionKey, {
          title: title
        })
        console.log('createNewUnit', result)
        this.handleNewUnit(result)
        this.$buefy.snackbar.open({
          message: 'Módulo criado com sucesso.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 2000
        })
      })
      this.closeModal()
    },

    async createNewTopic(topic) {
      console.log('createNewTopic', topic)
      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.createTopic(this.courseKey, this.editionKey, this.moduleKey, topic)
        console.log(response)
        this.handleNewTopic(response.topic)
        //show snackbar
        this.$buefy.snackbar.open({
          message: 'Tema criado com sucesso.',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 2000
        })
      })
      this.closeModal()
    },

    openPost(post) {
      this.post = post
    }
  }
}
</script>

<i18n lang="json">
  {
  "pt": {
    "edition": "Edição",
    "editionStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Terminado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  },
  "en": {
    "edition": "Edition",
    "editionStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Terminado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  }
}
</i18n>

<style scoped>
.drag-handle-new {
  @apply h-6 w-6 rounded-lg items-center flex justify-center text-center absolute left-3 top-3.5 z-10;
  cursor: grab;
}
</style>
