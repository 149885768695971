<template>
  <fw-layout
    back-to-enable
    :back-to="'-1'"
    :right-sidebar-expanded="true"
    :right-sidebar-width="'editor-sidebar'"
    :main-sidebar="false"
    :footer="false"
    :connected-users="connectedUsers"
    wide
    full
    inner-content-scrollable
    paddingless
  >
    <template #header-nav>
      <div class="flex gap-3 items-center">
        <div v-if="page" class="flex gap-3 items-center">
          <fw-heading size="md" marginless class="line-clamp-1 max-w-md" :class="{ 'text-gray-400': title == null }">{{
            title != null ? title : 'Sem título'
          }}</fw-heading>
          <b-tooltip v-if="titleTooltip" :label="titleTooltip" type="is-light" position="is-bottom">
            <fw-icon-info class="text-gray-400 h-4 w-4" />
          </b-tooltip>
        </div>
      </div>
    </template>
    <template #main-content>
      <PanelDocument
        v-if="pageKey"
        :page-key="pageKey"
        :edit-mode="editMode"
        :prop-version-key="versionKey"
        :show-left-sidebar="false"
        :show-subpages="false"
        :allowed-editor-tabs="allowedEditorTabs"
        @change-version="goToVersion"
        @preview="openDoc($event, false)"
        @open="openDoc($event, editMode)"
        @edit="openDoc($event, true)"
        @page-loaded="page = $event"
      />
    </template>
  </fw-layout>
</template>

<script>
import PanelDocument from '@/fw-modules/fw-core-vue/pages/components/panels/PanelDocument'

export default {
  components: {
    PanelDocument
  },
  data() {
    return {
      page: null
    }
  },
  computed: {
    allowedEditorTabs() {
      return this.page && this.page.application == 'courses'
        ? ['editor', 'versions', 'comments']
        : ['editor', 'versions', 'comments', 'settings']
    },
    connectedUsers() {
      return this.$store.getters.getConnectedUsers.users
    },

    pageKey() {
      return this.$route.params.key
    },

    versionKey() {
      return this.$route.params.version
    },

    editMode() {
      return this.$route.meta.edit
    },

    title() {
      return this.page ? this.page.title : null
    },
    titleTooltip() {
      if (this.page.application == 'courses' && this.page.context == 'unit') {
        return 'Pode alterar o título do módulo na área de gestão "Módulos"'
      } else if (this.page.application == 'courses' && this.page.context == 'topic') {
        return 'Pode alterar o título do tema na área de gestão "Módulos"'
      } else {
        return null
      }
    }
  },

  methods: {
    openDoc(page, edit) {
      console.log('openDoc :>> ', page)
      if (edit) {
        this.$router.push({
          name: 'content-pages-editor',
          params: { key: page.key }
        })
        return
      }
      this.$router.push({
        name: 'content-pages-viewer',
        params: { key: page.key }
      })
    },

    goToVersion(newVersion) {
      if (this.editMode) {
        this.$router.push({
          name: 'content-pages-editor-with-version',
          params: { key: this.pageKey, version: newVersion.key }
        })
        return
      }
      this.$router.push({
        name: 'content-pages-viewer-with-version',
        params: { key: this.pageKey, version: newVersion.key }
      })
    }
  }
}
</script>
