<template>
  <div
    class="flex px-3 cursor-pointer py-2 gap-1 my-0.5 relative w-full text-left group rounded-lg items-start"
    :class="{
      'bg-white shadow-md': data.key == selected,
      'bg-gray-100 hover:text-gray-800': data.key !== selected
    }"
  >
    <div class="flex-1 pl-1 min-w-0 flex flex-col gap-1">
      <div class="flex-1 overflow-hidden">
        <v-clamp v-if="collectionTitle" autoresize :max-lines="1" class="font-medium pr-5 text-xs text-gray-500">
          {{ collectionTitle }}
        </v-clamp>
        <v-clamp
          autoresize
          :max-lines="2"
          class="font-medium pr-5"
          :class="{
            'h-12': collectionTitle == null,
            'h-8': collectionTitle != null
          }"
        >
          {{ data.title || 'Sem título' }}
        </v-clamp>
      </div>
      <div class="flex gap-2 items-center">
        <div v-if="data.updated_date" class="text-xs text-gray-500 flex gap-2 flex-1">
          <div>Atualizada em {{ data.created_date | formatDateTime }}</div>
        </div>
        <div v-else-if="data.created_date" class="text-xs text-gray-500 flex gap-2 flex-1">
          <div>Criada em {{ data.created_date | formatDateTime }}</div>
        </div>
        <fw-tag v-if="data.status == 'draft'" type="light-orange">Rascunho</fw-tag>
        <fw-tag v-else-if="data.status == 'published'" type="light-primary">Publicada</fw-tag>
      </div>
    </div>
    <fw-dot :color="data.has_updates ? 'primary' : 'light'" :ping="false" class="m-1" />
  </div>
</template>

<script>
export default {
  name: 'RecordPost',
  props: {
    data: {
      type: Object
    },
    selected: {
      type: String,
      default: null
    },
    users: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    collectionTitle() {
      return this.data && this.data.collection ? this.data.collection.name : null
    }
  }
}
</script>
<style scoped>
.exam-record .item-arrow {
  @apply transition-all;
}
.exam-record:hover .item-arrow {
  transform: translateX(3px);
}
</style>
