<template>
  <section>
    <fw-heading size="h2">Pessoas</fw-heading>
    <fw-panel
      v-if="owner !== null && !edition?.key"
      title="Responsável do espaço"
      boxed="xs"
      custom-class="bg-white"
      class="mt-5 mb-6"
      counter="1"
    >
      <fw-person :key="owner.key" :person="owner" open-details-on-click :selectable="false" :clickable="true">
        <template #options>
          <fw-button
            v-if="canEdit && owner.key == user.key"
            type="link"
            @click.native="peopleModal('chose_people', 'owner')"
            >Alterar</fw-button
          >
        </template>
      </fw-person>
    </fw-panel>

    <fw-panel v-if="!edition?.key" title="Gestores do espaço" :boxed="managers.length ? 'xs' : null">
      <div class="flex flex-1 px-2 pb-2">
        <fw-button
          size="md"
          :type="activePage === 'managers' ? 'tab-active' : 'tab'"
          @click.native="activePage = 'managers'"
        >
          Ativos
          <span v-if="!loading.managers" class="text-xs opacity-50 ml-2">{{
            activePage === 'managers' ? managers.length : totalRemainingTabResults
          }}</span>
        </fw-button>

        <fw-button
          size="md"
          :type="activePage === 'pending-invites' ? 'tab-active' : 'tab'"
          @click.native="activePage = 'pending-invites'"
        >
          Pendentes
          <span v-if="!loading.managers" class="text-xs opacity-50 ml-2">{{
            activePage === 'pending-invites' ? managers.length : totalRemainingTabResults
          }}</span>
        </fw-button>
      </div>
      <LoadingPlaceholder v-if="loading.managers" list></LoadingPlaceholder>
      <ExpandList
        v-else
        add-title="Adicionar gestores"
        :show-add-items="canEdit"
        :disabled="!canEdit"
        :empty="!managers.length"
        empty-title="Não existem gestores associados a este espaço."
        @add-element="peopleModal('chose_people', 'managers')"
      >
        <template #list>
          <fw-person
            v-for="(manager, e) in managers"
            :key="'manager' + e"
            :person="users[manager.user_key]"
            :selectable="false"
            :clickable="true"
            :open-details-on-click="true"
          >
            <template v-if="canRemove && user.key !== manager.user_key" #options>
              <fw-menu-more>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button type="basic-action" custom-class="w-full" @click.native="removeManager(manager.user_key)">
                    Remover</fw-button
                  >
                </b-dropdown-item>
              </fw-menu-more>
            </template>

            <template v-if="invites[manager.user_key]" #details-modal-more-meta>
              <BlockInviteDetails
                :user="users[manager.user_key]"
                :invite.sync="invites[manager.user_key]"
                :send-invite-endpoint="sendInvite"
              ></BlockInviteDetails>
            </template>
          </fw-person>
        </template>
      </ExpandList>
    </fw-panel>

    <PanelManageModulesPeople v-if="edition?.key" :course="course" :edition="edition"></PanelManageModulesPeople>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ managers, users, validations, invites }"></json-viewer>
    </fw-panel-info>

    <b-modal
      :active="modal != null"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChoosePeopleModal
        v-if="modal == 'chose_people'"
        :title="modalType == 'owner' ? 'Definir responsável do espaço' : 'Adicionar gestores do espaço'"
        :instructions="
          modalType == 'owner' ? 'Selecione um novo responsável pelo espaço.' : 'Selecione gestores do espaço.'
        "
        :multiselect="modalType != 'owner'"
        :not-alowed-users="notAlowedUsers"
        :invite-people="modalType != 'owner'"
        :endpoint="searchEndpoint"
        @selected="selectedPeople"
        @close="closeModal"
        @save-invite="saveInvite"
      ></ChoosePeopleModal>
      <ModalImportBulkUsers
        v-if="modal == 'import_bulk'"
        :search-engine="bulkSearch"
        @import="importPeople"
        @close="closeModal"
      ></ModalImportBulkUsers>
    </b-modal>
  </section>
</template>

<script>
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import PanelManageModulesPeople from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageModulesPeople'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import ModalImportBulkUsers from '@/fw-modules/fw-core-vue/ui/components/modals/ModalImportBulkUsers'
import BlockInviteDetails from '@/fw-modules/fw-core-vue/courses/components/blocks/BlockInviteDetails'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'

export default {
  components: {
    ExpandList,
    ChoosePeopleModal,
    LoadingPlaceholder,
    ModalImportBulkUsers,
    PanelManageModulesPeople,
    BlockInviteDetails
  },

  props: {
    course: {
      type: Object,
      default: () => ({})
    },
    edition: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: {
        managers: false
      },
      invites: {},
      users: {},
      managers: [],
      validations: {
        can_add: false,
        can_delete: false
      },
      modal: null,
      modalType: null,
      totalRemainingTabResults: 0,
      activePage: 'managers'
    }
  },

  computed: {
    canEdit() {
      return this.validations.can_add
    },

    canRemove() {
      return this.validations.can_delete
    },

    owner() {
      return this.users?.[this.course.user_key] ?? null
    },

    notAlowedUsers() {
      return this.managers.map(el => el.user_key).concat(this.course.user_key)
    },

    user() {
      return this.$store.getters.getUser
    }
  },

  watch: {
    activePage() {
      console.log('activePage', this.activePage)
      this.getManagers()
    }
  },

  mounted() {
    this.getManagers()
  },

  methods: {
    peopleModal(modal, type) {
      this.modal = modal
      this.modalType = type
    },
    async importPeople(impt) {
      this.selectedPeople(impt.users)
    },
    async selectedPeople(selection) {
      console.log(`selectedPeople type:${this.modalType}`, selection)
      switch (this.modalType) {
        case 'owner':
          this.updateOwner(selection)
          break
        case 'managers':
          this.updateManagers(selection)
          break
        default:
          console.log('not a type')
      }
    },
    async updateOwner(selection) {
      this.$buefy.dialog.confirm({
        title: 'Alterar responsável',
        message: `Tem a certeza quer deixar de ser o responsável do espaço? Se não for gestor, perderá acesso ao painel de administração do espaço.`,
        confirmText: 'Continuar',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            let result
            result = await ServiceCourses.updateCourseOwner(this.course.key, selection[0].key)
            console.log('updateCourseOwner', result)
            this.modal = null
            this.modalType = null
            this.$buefy.snackbar.open({
              message: `Responsável alterado.`,
              type: 'is-primary',
              position: 'is-top-right',
              duration: 3000
            })
            this.$router.push({ name: 'courses-manage' })
          } catch (e) {
            this.showError('Ocorreu um erro ao modificar o responsável do espaço')
            console.error(e)
          }
        }
      })
    },
    async updateManagers(selection, invites = []) {
      if (!selection?.length && !invites?.length) return
      const toAdd = invites?.length ? (selection ?? []).concat(invites) : selection

      try {
        const result = await ServiceCourses.addCourseManagers(
          this.course.key,
          toAdd.map(el => el.key)
        )

        const usersKeys = selection?.map(el => el.key) ?? []
        const invitedUsersKeys = invites?.map(el => el.key) ?? []

        console.log('addCourseManagers', result)
        this.users = { ...this.users, ...result.users }
        this.invites = { ...this.invites, ...result.invites }

        for (const manager of result.managers) {
          if (usersKeys.includes(manager.user_key)) {
            if (this.activePage === 'managers') this.managers.push(manager)
            else this.totalRemainingTabResults += 1
          } else if (invitedUsersKeys.includes(manager.user_key)) {
            if (this.activePage === 'pending-invites') this.managers.push(manager)
            else this.totalRemainingTabResults += 1
          }
        }

        // this.managers = this.managers.concat(result.managers)
        this.modal = null
        this.$buefy.snackbar.open({
          message: `Gestores adicionados.`,
          type: 'is-primary',
          position: 'is-bottom-right',
          duration: 3000,
          queue: true
        })
      } catch (e) {
        this.showError('Ocorreu um erro ao adicionar o(s) gestor(es)')
        console.error(e)
      }
    },
    async removeManager(key) {
      //ask for confirmation
      this.$buefy.dialog.confirm({
        title: 'Remover gestor do espaço',
        message: `Tem a certeza que deseja remover este utilizador do papel de gestor do espaço?`,
        confirmText: 'Remover',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            let result = await ServiceCourses.removeCourseManagers(this.course.key, [key])
            console.log(result)
            this.managers = this.managers.filter(el => el.user_key != key)
            this.$buefy.snackbar.open({
              message: `Gestor removido.`,
              type: 'is-primary',
              position: 'is-top-right',
              duration: 3000,
              queue: true
            })
          } catch (e) {
            this.showError('Ocorreu um erro ao remover o gestor')
            console.error(e)
          }
        }
      })
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    async sendInvite(userKey) {
      return await ServiceCourses.sendUserInviteNotification(userKey)
    },
    async searchEndpoint(params) {
      return await ServiceCourses.searchUsers(this.course.key, null, null, {
        ...params,
        adding: this.modalType == 'owner' ? 'owner' : 'manager'
      })
    },
    async bulkSearch(query) {
      query = query.split(',')
      return await ServiceCourses.bulkSearchUsers(this.course.key, null, query, {
        adding: this.modalType == 'owner' ? 'owner' : 'manager'
      })
    },

    async getManagers() {
      if (this.edition?.key) return
      this.loading.managers = true
      utils.tryAndCatch(
        this,
        // Main code to run
        async () => {
          const response = await ServiceCourses.getCourseManagers(this.course.key, {
            active: this.activePage == 'pending-invites'
          })
          console.log('getCourseManagers :>> ', response)
          this.managers = response.managers
          if (response.validations) {
            this.validations = response.validations
          }
          this.users = { ...this.users, ...response.users }
          this.invites = { ...this.invites, ...response.invites }
          this.totalRemainingTabResults = response.total_remaining_invites
        },
        // Finally
        () => {
          this.loading.managers = false
        }
      )
    },

    async saveInvite(inviteData) {
      try {
        const response = await ServiceCourses.createUsersInvites([inviteData], true)
        const invites = response.invites
        console.log('invited ==>', invites)
        for (const invite of invites) {
          this.$buefy.snackbar.open({
            message: this.$t('accountAdded', { user: invite.name, email: invite.email }),
            type: 'is-primary',
            position: 'is-top-right',
            duration: 2000,
            queue: false
          })
        }
        this.updateManagers([], invites)
      } catch (error) {
        const apiError = utils.errors(error).get()
        console.log('apiError :>> ', apiError)
        if (apiError && apiError.key && apiError.key == 'UserAlreadyExists') {
          this.$buefy.dialog.alert({
            title: this.$t('userAlreadyExists', { user: apiError.name }),
            message: this.$t('userAlreadyExistsInfo'),
            type: 'is-danger'
          })
        } else {
          utils.errorDialogAlert(this, error)
        }

        console.error(error)
      }
    },

    closeModal() {
      this.modal = null
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "leader": "Líder",
    "confirmedAt": "Confirmada em",
    "deniedAt": "Rejeitada em",
    "deletedAt": "Removida em",
    "messages": "Mensagens",
    "logs": "Atividade",
		"accountAdded": "A conta de {user} ({email}) foi adicionada.",
    "userAlreadyExists": "O utilizador {user} já existe",
    "userAlreadyExistsInfo": "O utilizador que tentou convidar já existe."
  },
  "en": {
    "leader": "Líder",
    "confirmedAt": "Confirmada em",
    "deniedAt": "Rejeitada em",
    "deletedAt": "Removida em",
    "messages": "Mensagens",
    "logs": "Atividade",
		"accountAdded": "The account of {user} ({email}) was added.",
    "userAlreadyExists": "User {user} already exists",
    "userAlreadyExistsInfo": "The user you tried to invite already exists."
  }
}
</i18n>
