var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SidebarBase',{attrs:{"paddingless":_vm.isBreadCrumb},scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('ButtonSidebar',{attrs:{"label":"Preview","icon":"eye","bordered":"","to":{ name: 'course-edition', params: { courseKey: _vm.courseKey, editionKey: _vm.editionKey } }}}),_c('ButtonSidebar',{attrs:{"label":"Dashboard","icon":"dashboard","active":_vm.view == 'dashboard',"to":_vm.resolve('course-manage-edition', 'dashboard')}}),_c('ButtonSidebar',{attrs:{"label":"Metadados","active":_vm.view == 'metadata',"icon":"journal","to":_vm.resolve('course-manage-edition', 'metadata')}}),_c('ButtonSidebar',{attrs:{"label":"Módulos","active":_vm.view == 'modules',"icon":"book-mark-solid","to":_vm.goToModules()}}),_c('ButtonSidebar',{attrs:{"active":_vm.isClassesView,"icon":"cube","label":"Turmas","to":_vm.classes?.length
          ? {
              name: 'course-manage-edition-class',
              params: { id: _vm.courseKey, editionId: _vm.editionKey, classKey: _vm.classes?.[0]?.key }
            }
          : {
              name: 'course-manage-edition',
              params: { id: _vm.courseKey, editionId: _vm.editionKey, view: 'classes' }
            }},scopedSlots:_vm._u([(!_vm.isBreadCrumb)?{key:"suffix",fn:function(){return [(_vm.isClassesView)?_c('fw-icon-chevron-up',{staticClass:"w-4 h-4"}):_c('fw-icon-chevron-down',{staticClass:"w-4 h-4"})]},proxy:true}:null],null,true)}),(_vm.isClassesView && !_vm.isBreadCrumb)?_c('VerticalSteps',{staticClass:"mb-2",attrs:{"steps":_vm.classesSteps,"current-step":_vm.currentClass,"truncate-text":true},on:{"clicked":_vm.goToClass}}):_vm._e(),_c('ButtonSidebar',{attrs:{"active":_vm.view == 'discussions',"icon":"chat-thread","label":"Fóruns","to":{
        name: 'course-manage-edition-post-collections',
        params: { id: _vm.courseKey, editionId: _vm.editionKey, view: 'discussions' }
      }},scopedSlots:_vm._u([(!_vm.isBreadCrumb)?{key:"suffix",fn:function(){return [(_vm.view == 'discussions')?_c('fw-icon-chevron-up',{staticClass:"w-4 h-4"}):_c('fw-icon-chevron-down',{staticClass:"w-4 h-4"})]},proxy:true}:null],null,true)}),(_vm.view == 'discussions')?_c('VerticalSteps',{staticClass:"mb-2",attrs:{"steps":_vm.forunsSteps,"current-step":_vm.activeCollectionKey,"truncate-text":true},on:{"clicked":_vm.goToCollection}}):_vm._e(),_c('ButtonSidebar',{attrs:{"label":"Pessoas","icon":"people","active":_vm.view == 'people',"to":_vm.resolve('course-manage-edition', 'people')}}),_c('ButtonSidebar',{attrs:{"label":"Canais","active":_vm.view == 'chats',"icon":"chats","to":_vm.resolve('course-manage-edition', 'chats')}}),(_vm.edition && _vm.edition.bucket_key)?_c('ButtonSidebar',{attrs:{"label":"Ficheiros","icon":"box-open","active":_vm.view == 'bucket',"to":_vm.resolve('course-manage-edition', 'bucket')}}):_vm._e(),_c('ButtonSidebar',{staticClass:"rounded-md",attrs:{"to":_vm.resolve('course-manage-edition', 'activity'),"icon":"activity","label":"Atividade","active":_vm.view == 'activity'}}),_c('ButtonSidebar',{staticClass:"rounded-md",attrs:{"to":_vm.resolve('course-manage-edition', 'notifications'),"label":"Notificações","icon":"message-sent","active":_vm.view == 'notifications'}}),_c('ButtonSidebar',{attrs:{"active":_vm.isSettingsView,"icon":"settings","label":"Configurações","to":_vm.resolve('course-manage-edition', 'settings-edition')}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }