<template>
  <fw-layout
    :key="`${course?.key}-${edition?.key}`"
    :full="isFullLayout"
    :notfound="notfound"
    :loading="initialLoading"
    loading-title="Edição de Espaço"
    loading-icon="cube"
    :paddingless="view == 'metadata'"
    :back-to-enable="false"
  >
    <template #header-nav>
      <div class="flex gap-5 items-center">
        <div class="flex items-center">
          <fw-button-dropdown
            aria-role="listitem"
            type="transparent"
            :chevron="false"
            :expanded="false"
            @click.native="goToSpace()"
          >
            <span class="font-semibold text-base line-clamp-1 max-w-xs">{{ course?.title ?? 'Espaço' }}</span>
          </fw-button-dropdown>
          <div class="h-8 w-5 text-center font-medium text-2xl text-gray-200">
            /
          </div>
          <b-dropdown aria-role="list" position="is-bottom-right" append-to-body>
            <fw-button-dropdown slot="trigger" aria-role="listitem" type="transparent">
              <span class="font-semibold text-base line-clamp-1 max-w-xs">{{ edition.title ?? 'Edição' }}</span>
            </fw-button-dropdown>
            <b-dropdown-item custom aria-role="menuitem">
              <fw-label size="xs" marginless>Edições</fw-label>
            </b-dropdown-item>
            <b-dropdown-item v-for="item in editions" :key="item.key" aria-role="listitem" has-link>
              <router-link
                :to="{
                  name: 'course-manage-edition',
                  params: { id: courseKey, editionId: item.key, view: 'dashboard' }
                }"
              >
                <div class="flex items-center gap-5 justify-between font-medium">
                  <div
                    class="font-medium flex gap-2"
                    :class="`${edition?.key == item.key ? 'text-primary font-bold' : 'text-gray-500'}`"
                  >
                    <div><fw-icon-cube class="w-5 h-5" /></div>
                    <div class="line-clamp-1 max-w-xs">{{ item.title ?? 'Edição sem título definido' }}</div>
                  </div>
                  <div
                    class="text-xs"
                    :class="{
                      'text-gray-500': item.status === 'published',
                      'text-gray-400': item.status !== 'published'
                    }"
                  >
                    {{ $t(`editionStatus.${item.status}`) }}
                  </div>
                </div>
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div>
          <fw-tag size="xs" :type="edition.status == 'draft' ? 'orange' : 'light-primary'">
            {{ $t(`editionStatus.${edition.status}`) }}
          </fw-tag>
        </div>
      </div>
    </template>

    <template #main-sidebar>
      <SidebarManageCourseEdition
        :active-collection-key="collectionId"
        :course-key="courseKey"
        :edition-key="editionKey"
        :edition="edition"
        :collections="collections"
        :classes="classes"
        :units="units"
        :active-unit="moduleId"
        :validations="validations"
      />
    </template>

    <template #main-content>
      <PanelBucket v-if="view === 'bucket'" :prop-bucket-key="edition?.bucket_key" />
      <PanelManageCourseEditionDashboard v-else-if="view === 'dashboard'" :course="course" :edition="edition" />
      <PanelManageCourseEditionClass
        v-else-if="view === 'classes' && edition"
        :course="course"
        :edition="edition"
        :classes="classes"
        :can-edit="validations.edition.can_edit"
        @class-created="onClassCreated"
        @class-deleted="onClassDeleted"
      />
      <PanelManageCourseEditionModulesAll
        v-else-if="false && view === 'modules' && edition && !moduleId"
        :course="course"
        :edition="edition"
        :editable="validations.edition.can_edit"
      />
      <PanelPosts
        v-else-if="view === 'discussions' && edition && !collectionId && collections"
        type="discussions"
        :collections="collections"
        @open="openPost($event)"
      />
      <PanelManageCourseEditionPosts
        v-else-if="view === 'discussions' && edition && collectionId"
        :key="collectionId"
        :course="course"
        :edition="edition"
        :view="view"
      />
      <PanelManageCourseEditionMetadata
        v-else-if="view === 'metadata' && edition"
        :editable="validations.edition.can_edit"
        :course="course"
        :edition="edition"
        :saving="saving"
        @save="saveEdition"
      />
      <PanelManageCourseSettings
        v-else-if="view === 'settings-space' && course"
        :editable="validations.course.can_edit"
        :course="course"
        @save-metadata="saveCourseMetadata"
        @save="saveCourse"
      />
      <PanelManageCourseEditionSettings
        v-else-if="view === 'settings-edition' && edition"
        :editable="validations.edition.can_edit"
        :course="course"
        :edition="edition"
        @updated="edition = $event"
        @save="saveEdition"
        @save-silent="saveEdition($event, true)"
      />
      <PanelManageCoursePeople v-else-if="view === 'people'" :course="course" :edition="edition" />
      <PanelManageCourseNotifications v-else-if="view === 'notifications'" :course="course" :edition="edition" />
      <PanelManageCourseActivity v-else-if="view === 'activity'" :course="course" :edition="edition" />
      <PanelManageCourseEditionChats v-else-if="view === 'chats'" :course-key="course.key" :edition-key="edition.key" />
    </template>
  </fw-layout>
</template>

<script>
import PanelManageCourseEditionDashboard from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionDashboard.vue'
import PanelManageCourseEditionSettings from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionSettings.vue'
import PanelManageCourseEditionMetadata from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionMetadata.vue'
import ServiceCourses from '@/fw-modules/fw-core-vue/courses/services/ServiceCourses'
import SidebarManageCourseEdition from '@/fw-modules/fw-core-vue/courses/components/sidebars/manage/SidebarManageCourseEdition'
import PanelManageCourseEditionClass from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionClass.vue'
import PanelManageCourseEditionModulesAll from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionModulesAll.vue'
import PanelManageCourseEditionPosts from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionPosts.vue'
import PanelManageCourseNotifications from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseNotifications'
import PanelManageCoursePeople from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCoursePeople'
import PanelManageCourseActivity from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseActivity'
import PanelManageCourseEditionChats from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseEditionChats'
import PanelManageCourseSettings from '@/fw-modules/fw-core-vue/courses/components/panels/manage/PanelManageCourseSettings'
import PanelPosts from '@/fw-modules/fw-core-vue/posts/components/panels/PanelPosts'
import PanelBucket from '@/fw-modules/fw-core-vue/buckets/components/panels/PanelBucketv2'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    SidebarManageCourseEdition,
    PanelManageCourseEditionDashboard,
    PanelManageCourseEditionSettings,
    PanelManageCourseEditionClass,
    PanelManageCourseEditionPosts,
    PanelManageCourseEditionModulesAll,
    PanelManageCourseEditionMetadata,
    PanelManageCoursePeople,
    PanelManageCourseNotifications,
    PanelManageCourseActivity,
    PanelManageCourseEditionChats,
    PanelManageCourseSettings,
    PanelPosts,
    PanelBucket
  },
  data() {
    return {
      course: null,
      initialLoading: true,
      edition: null,
      units: [],
      collections: null,
      editions: [],
      wsSubscription: null,
      validations: {
        course: {
          can_edit: false
        },
        edition: {
          can_edit: false
        }
      },
      saving: false
    }
  },
  computed: {
    isCreator() {
      return true
    },
    isManager() {
      return true
    },
    courseKey() {
      return this.$route.params.id
    },
    editionKey() {
      return this.$route.params.editionId
    },
    view() {
      return this.$route.meta.view || this.$route.params.view || 'dashboard'
    },
    collectionId() {
      return this.$route.params.collectionId
    },
    isFullLayout() {
      return Boolean(
        (this.view && ['chats', 'agenda', 'bucket'].includes(this.view)) ||
          (this.view == 'discussions' && this.collectionId)
      )
    },
    notfound() {
      if (!this.initialLoading && !this.course) return true

      if (
        ![
          'dashboard',
          'classes',
          'modules',
          'metadata',
          'settings-space',
          'settings-edition',
          'people',
          'notifications',
          'activity',
          'chats',
          'discussions',
          'bucket'
        ].includes(this.view)
      )
        return true

      return false
    },
    moduleId() {
      return this.$route.params.moduleId
    }
  },

  watch: {
    view() {
      this.routerReact()
    },

    editionKey(newVal) {
      if (!newVal) return
      this.unsubscribeWs()
      this.collections = null
      this.loadInitialData()
      this.subscribeWs()
    }
  },

  beforeDestroy() {
    this.unsubscribeWs()
  },

  created() {
    this.loadInitialData()
    this.subscribeWs()
  },

  methods: {
    goToSpace() {
      this.$router.push({
        name: 'course-manage',
        params: { id: this.courseKey }
      })
    },
    handleWsMessages(messages) {
      console.log('messages manage :>> ', messages)
    },

    subscribeWs() {
      if (!this.wsSubscription) {
        this.wsSubscription = ServiceCourses.createEditionSubscription(this.editionKey, this.handleWsMessages)
        window.addEventListener('beforeunload', this.unsubscribeWs, {
          capture: true
        })
      }
    },

    unsubscribeWs() {
      if (this.wsSubscription) {
        this.wsSubscription.destroy()
        this.wsSubscription = null
      }
    },

    async loadInitialData() {
      await Promise.all([this.loadCourse(), this.loadEdition(), this.loadEditions()])
      this.initialLoading = false
      this.routerReact()
    },

    async loadClassCollections() {
      if (!this.editionKey || !this.courseKey) return

      await utils.tryAndCatch(this, async () => {
        let res = await ServiceCourses.getEditionPostsCollections(this.courseKey, this.editionKey)
        console.log('loadClassCollections', res)
        this.collections = res.collections
      })
    },

    async loadUnits() {
      if (!this.editionKey || !this.courseKey) return

      try {
        let res = await ServiceCourses.getUnits(this.courseKey, this.editionKey)
        console.log('loadUnits', res)
        this.units = res.units
      } catch (e) {
        console.error(e)
      }
    },

    async loadCourse() {
      await utils.tryAndCatch(this, async () => {
        let res = await ServiceCourses.getCourse(this.courseKey)
        console.log('getCourse :>> ', res)
        this.course = res.course
        this.validations.course = res.validations
      })
    },

    async saveCourse(data) {
      console.log(data)
      this.course[data.key] = data.value

      let body = JSON.parse(JSON.stringify(this.course))
      delete body.user_key
      utils.tryAndCatch(this, async () => {
        let course = await ServiceCourses.updateCourse(this.courseKey, body)
        console.log('update course', course)
        this.course = course

        this.$buefy.snackbar.open({
          message: 'Dados guardados.',
          type: 'is-primary'
        })
      })
    },

    async saveCourseMetadata(metadata) {
      console.log(metadata)
      this.course.duration = metadata.duration
      this.course.mode = metadata.mode
      this.course.title = metadata.title

      utils.tryAndCatch(this, async () => {
        let course = await ServiceCourses.updateCourse(this.courseKey, this.course)
        console.log('update course', course)
        this.course = course

        this.$buefy.snackbar.open({
          message: 'Dados guardados.',
          type: 'is-primary'
        })
      })
    },

    async saveEdition(data, silence = false) {
      console.log('save Edition', data)
      this.saving = true
      utils.tryAndCatch(
        this,
        async () => {
          let res = await ServiceCourses.updateEdition(this.courseKey, this.editionKey, data)
          console.log('update edition', res)
          this.edition = res.edition
          if (res['__errors__'] && res['__errors__'].length > 0) {
            utils.errorDialogAlert(this, res['__errors__'])
          } else if (!silence) {
            this.$buefy.snackbar.open({
              message: 'Dados guardados.',
              type: 'is-primary'
            })
          }
        },
        () => {
          setTimeout(() => {
            this.saving = false
          }, 250)
        }
      )
    },

    async loadEdition() {
      if (!this.editionKey || !this.courseKey) return
      await utils.tryAndCatch(this, async () => {
        let res = await ServiceCourses.getEdition(this.courseKey, this.editionKey)
        console.log('getEdition', res)
        this.edition = res.edition
        this.classes = res.classes
        this.validations.edition = res.validations
      })
    },

    async loadEditions() {
      if (!this.courseKey) return

      await utils.tryAndCatch(this, async () => {
        const response = await ServiceCourses.getEditions(this.courseKey)
        console.log('editions', response)
        this.editions = response.editions
      })
    },

    routerReact() {
      if (this.view === 'discussions') {
        this.loadClassCollections()
      } else if (this.view === 'modules') {
        this.loadUnits()
      }
    },
    onClassDeleted(deletedClassKey) {
      console.log('onClassDeleted', deletedClassKey)
      this.classes = this.classes.filter(c => c.key !== deletedClassKey)
    },
    onClassCreated(newClass) {
      console.log('newClass', newClass)
      this.classes = [
        ...this.classes,
        {
          name: newClass.name,
          key: newClass.key
        }
      ]
    },

    openPost(post) {
      console.log('openPost', post)
      this.$router.push({
        name: 'course-manage-edition-post-collection',
        params: {
          id: this.courseKey,
          editionId: this.editionKey,
          collectionId: post.collection.key,
          postId: post.key
        }
      })
    }
  }
}
</script>

<i18n lang="json">
{
  "pt": {
    "edition": "Edição",
    "editionStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Terminado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  },
  "en": {
    "edition": "Edition",
    "editionStatus": {
      "draft": "Rascunho",
      "published": "Publicado",
      "running": "A decorrer",
      "closed": "Terminado",
      "ended": "Terminado",
      "scheduled": "Agendado"
    }
  }
}
</i18n>
